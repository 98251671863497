import React, {useEffect, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import Axios from "axios";

export const People = () => {
    const [data, setData] = useState({})
    const getConf = () => {
        const url = `/website/getPeopleOnlineConf`;
        Axios.get(url)
            .then((res) => {
                const cur = res.data.data;
                setData(cur)
            })
            .catch((e) => console.log(e));
    };
    useEffect(() => {
        getConf();
    }, []);
    const {mainPhotos, content, roles, joinOurLab, collaborators, alumniList} = data;
    return (
        <div id="team">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Carousel
                            showArrows={false}
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                        >
                            {mainPhotos?.length > 0 &&
                                mainPhotos?.map((i, idx) => (
                                    <div key={i + idx}>
                                        <img
                                            style={{width: "100%", height: "auto"}}
                                            src={i}
                                            alt=""
                                        />
                                    </div>
                                ))}
                        </Carousel>
                    </div>
                    <div className="intro-text">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: `${content ? content : "loading"}`,
                            }}
                        ></p>
                    </div>
                </div>
                <div className="row text-left">
                    {roles
                        ? roles?.map((itm, i) => (
                            <div>
                                <h3 className="text-center">{itm?.roleType}</h3>
                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                    <div
                                        key={`${itm?.name}-${i}`}
                                        className="col-lg-12 col-md-24 col-sm-24 team-item"
                                    >
                                        <div className="thumbnail">
                                            {itm.photo && <img src={itm.photo} alt="..."
                                                               className="team-img col-md-24 col-sm-24"/>}
                                            <div className="caption text-left">
                                                <h4>{itm?.name}</h4>
                                                <p
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => window.open(itm?.cv)}
                                                >
                                                    resume
                                                </p>
                                                <p>{itm?.contact}</p>
                                                <p>{itm?.personalWeb}</p>
                                                <p>{itm?.personalDesc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : "loading"}
                </div>
                <div className="text-left">
                    <h3>Join us</h3>
                </div>
                <div>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: `${joinOurLab ? joinOurLab.content : ""}`,
                        }}
                    ></p>
                </div>
                <div className="section-title text-left">
                    <h3>Collaborators</h3>
                </div>
                {collaborators &&
                    collaborators?.map((d) => {
                        return (
                            <>
                                <h4>{d?.collaboratorName}</h4>
                                <div style={{marginTop: "10px"}}></div>
                                <a target="_blank" href={d?.collaboratorLinked}
                                   rel="noreferrer">{d?.collaboratorLinked}</a>
                            </>
                        );
                    })}

                <div className="section-title text-left">
                    <h3>Alumni</h3>
                </div>
                {alumniList &&
                    alumniList?.map((d) => {
                        return (
                            <div style={{marginBottom: '30px'}}>
                                <h4>{d?.alumniName}</h4>
                                <p> {d?.alumniLevel}</p>
                                <p> {d?.personWeb}</p>
                                <p> {d?.personDesc}</p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
