import React, {useEffect, useState} from "react";
import Axios from "axios";


export const Research = () => {
    const [data, setData] = useState({})
    const getConf = () => {
        const url = `/website/getResearchOnlineConf`;
        Axios.get(url)
            .then((res) => {
                const cur = res.data.data;
                setData(cur)
            })
            .catch((e) => console.log(e));
    };
    useEffect(() => {
        getConf();
    }, []);
    const {title, overview, subViews} = data;
    return (
        <div id="research">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="research-text">
                            <h2>{title}</h2>
                            <div
                                className="col-md-12"
                                dangerouslySetInnerHTML={{
                                    __html: `${overview}`,
                                }}
                            ></div>
                            {subViews
                                ? subViews?.map((d, i) => (
                                    <>
                                        <h3>{d?.subTitle}</h3>
                                        <div className="col-md-12" key={`${d?.subOrder}-${i}`}
                                             dangerouslySetInnerHTML={{
                                                 __html: `${d?.subContent}`,
                                             }}
                                        ></div>
                                    </>
                                ))
                                : "loading"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
