import React, {useEffect, useState} from "react";
import Axios from "axios";
export const Contact = () => {
  const [data, setData] = useState({})
  const getContactConf = () => {
    const url = `/website/getContactOnlineConf`;
    Axios.get(url)
        .then((res) => {
          const cur = res.data.data;
          setData(cur)
        })
        .catch((e) => console.log(e));
  };
  useEffect(() => {
    getContactConf();
  }, []);
  const {email, labPhone, address, mapUrl} = data;
  return (
    <div id="contact">
      <div className="container">
        <div className="col-md-24 contact-info">
          <h3>Contact Info</h3>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-phone" /> Phone
              </span>{" "}
              {labPhone ? labPhone : "loading"}
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope-o" /> Email
              </span>{" "}
              {email ? email : "loading"}
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-map-marker" /> Address
              </span>
              {address ? address : "loading"}
            </p>
          </div>
          <div className="contact-item">
            {/* <ReaderMap /> */}
            <img
              style={{ width: "60%", height: "auto" }}
              src={mapUrl?mapUrl:""}
              alt=""
            />
          </div>
          <div className="contact-item contact-right">
            <a href="https://beian.miit.gov.cn/" target="_blank">
              黑ICP备2023001926号
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
