import React, {useState} from "react";
import {Link} from "react-router-dom";

export const Navigation = (props) => {
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(!isFocused);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 300)
    };
    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                    </button>
                    <Link className="navbar-brand page-scroll" to="/">
                        <img
                            src="img/logo-01.png"
                            alt="PANLAB"
                            style={{height: '40px'}}
                        />
                    </Link>{" "}
                </div>

                <div
                    className="collapse navbar-collapse"
                    style={{display: isFocused ? 'block' : 'none'}}
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <Link to="/research" className="page-scroll">
                                RESEARCH
                            </Link>
                        </li>
                        <li>
                            <Link to="/publications" className="page-scroll">
                                Publications
                            </Link>
                        </li>
                        <li>
                            <Link to="/people" className="page-scroll">
                                PEOPLE
                            </Link>
                        </li>
                        <li>
                            <Link to="/labPhotos" className="page-scroll">
                                Lab Photos
                            </Link>
                        </li>

                        <li>
                            <Link to="/news" className="page-scroll">
                                News
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
