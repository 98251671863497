import React from "react";
import {Navigation} from "./components/navigation";
import {Home} from "./components/home";
import {Research} from "./components/research";//RESEARCH
import {LabPhotos} from "./components/labPhotos";
import {News} from "./components/news";
import {NewsDetail} from "./components/newsDetail";
import {Publications} from "./components/publications";
import {People} from "./components/people";
import {Contact} from "./components/contact";
import SmoothScroll from "smooth-scroll";
import {Route, Routes} from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});


const App = () => {
    return (
        <div>
            <Navigation/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/research" element={<Research/>}/>
                <Route path="/publications" element={<Publications/>}/>
                <Route path="/people" element={<People/>}/>
                <Route path="/labPhotos" element={<LabPhotos/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/newsDetail" element={<NewsDetail/>}/>
            </Routes>
            <Contact/>
        </div>
    );
};

export default App;
