import React, {useEffect, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import Axios from "axios";

export const Home = () => {
    const [data, setData] = useState({})
    const getHomeConf = () => {
        const url = `/website/getHomeOnlineConf`;
        Axios.get(url)
            .then((res) => {
                const cur = res.data.data;
                setData(cur)
            })
            .catch((e) => console.log(e));
    };
    useEffect(() => {
        getHomeConf();
    }, []);
    const {banners, content} = data;
    return (
        <div className="intro">
            <div className="overlay">
                <div className="container">
                    <Carousel
                        showArrows={false}
                        showThumbs={false}
                        showStatus={false}
                        autoPlay={true}
                    >
                        {banners?.length > 0 &&
                            banners?.map((i, idx) => (
                                <div key={i + idx}>
                                    <img
                                        style={{width: "100%", height: "auto"}}
                                        src={i}
                                        alt=""
                                    />
                                </div>
                            ))}
                    </Carousel>

                    <div className="intro-text">
                        <p
                            className="col-md-12"
                            dangerouslySetInnerHTML={{
                                __html: `${content}`,
                            }}
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    );
};
