import React, {useEffect, useState} from "react";
import wx from "weixin-jweixin";
import Axios from "axios";

export const NewsDetail = () => {
    const [data, setData] = useState({})
    const param = window.location.search.slice(1).split("=")?.[1];
    const getDetail = () => {
        const url = `/website/getNewsDdetail?id=${param}`;
        Axios.get(url)
            .then((res) => {
                const cur = res.data.data;
                setData(cur)
            })
            .catch((e) => console.log(e));
    };
    useEffect(() => {
        getDetail();
    }, []);
    const link = window.location.href

    const getSignature = () => { //获取微信签名
        return new Promise((resolve, reject) => {
            Axios.get('/api/wx/getAccessToken?link=' + link, {}).then(res => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }
    const handleShare = async (title) => {
        try {
            const res = await getSignature(link)
            const {debug, appId, nonceStr, signature, timestamp} = res.data

            wx.config({
                debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature, // 必填，签名
                jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
            });
            wx.ready(() => {
                wx.updateAppMessageShareData({
                    title, // 分享标题
                    link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl:
                        "http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png", // 分享图标
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                    },
                });
                wx.updateTimelineShareData({
                    title, // 分享标题
                    link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl:
                        "http://icon.mobanwang.com/UploadFiles_8971/200910/20091011134333685.png", // 分享图标
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                    },
                });
            });
        } catch (error) {
            console.error('Error while fetching signature:', error.message);
        }


    };
    const {newsTitle, newsPubTime, newsContent} = data;
    return (
        <div id="news">
            <div className="container">
                <div className="section-title text-center">
                </div>
                <div className="row">
                    <div className="text-center">
                        <h3 style={{cursor: "pointer"}}>{newsTitle}</h3>
                        <h4>{newsPubTime}</h4>
                    </div>
                    <p
                        className="col-md-12"
                        dangerouslySetInnerHTML={{
                            __html: `${newsContent}`,
                        }}
                    ></p>
                </div>
                <div
                    className="share"
                    onClick={() => handleShare(newsTitle)}
                >
                    share
                </div>
            </div>
        </div>
    );
};
