import React, {useEffect, useState} from "react";
import Axios from "axios";

export const News = () => {
        const [data, setData] = useState({})
        const getPeopleConf = () => {
            const url = `/website/getNewsOnlineCof`;
            Axios.get(url)
                .then((res) => {
                    const cur = res.data.data;
                    setData(cur)
                })
                .catch((e) => console.log(e));
        };
        useEffect(() => {
            getPeopleConf();
        }, []);
        const dataList = data;
        useEffect(() => {
            // 在渲染后移除 span
            document.querySelectorAll('span[style="color:#ffffff"]').forEach(p => p.remove());
        }, [dataList]); // 当 dataList 变化时执行
        return (
            <div id="news" className="text-center">
                <div className="container">
                    <div className="section-title text-center">
                        <h2>News</h2>
                    </div>
                    {dataList && Array.isArray(dataList) &&
                        dataList.map((item) => {
                            return (
                                <>
                                    <div className="row">
                                        <div className="text-center">
                                            <h3
                                                style={{cursor: "pointer"}}
                                                onClick={() =>
                                                    (window.location.href = `/newsDetail?id=${item.id}`)
                                                }
                                            >
                                                {item?.newsTitle}
                                            </h3>
                                            <h4>{item?.newsPubTime}</h4>
                                        </div>
                                        <div className="intro-text">
                                            <p
                                                className="col-md-12"
                                                dangerouslySetInnerHTML={{
                                                    __html: `${item?.newsContent}`,
                                                }}
                                            ></p>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                </div>
            </div>
        );
    }
;
