import React, {useEffect, useState} from "react";
import {Image} from "./image";
import Axios from "axios";


export const LabPhotos = () => {
    const [data, setData] = useState({})
    const getPeopleConf = () => {
        const url = `/website/getPhotosOnlineConf`;
        Axios.get(url)
            .then((res) => {
                const cur = res.data.data;
                setData(cur)
            })
            .catch((e) => console.log(e));
    };
    useEffect(() => {
        getPeopleConf();
    }, []);
    const dataList = data;
    return (
        <div id="portfolio" className="text-center">
            <div className="container">
                <div className="row">
                    {dataList && Array.isArray(dataList) &&
                        dataList.map((itm) => {
                            return (
                                <div>
                                    <div className="section-title">
                                        <h2>{itm?.title}</h2>
                                    </div>

                                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                        {itm?.photosUrls?.map((d, i) => (
                                            <div
                                                key={`${d}-${i}`}
                                                className="col-sm-6 col-md-4 col-lg-4"
                                            >
                                                <Image
                                                    title={d}
                                                    largeImage={d}
                                                    smallImage={d}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
