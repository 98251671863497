import React, {useEffect, useState} from "react";
import Axios from "axios";

export const Publications = (props) => {
    const [data, setData] = useState({})
    const getConf = () => {
        const url = `/website/getPublicationsOnlineConf`;
        Axios.get(url)
            .then((res) => {
                const cur = res.data.data;
                setData(cur)
            })
            .catch((e) => console.log(e));
    };
    useEffect(() => {
        getConf();
    }, []);
    const dataList = data;
    return (
        <div id="publications">
            <div className="container">
                <div className="section-title text-center">
                    <h2>Publications</h2>
                </div>
                {dataList && Array.isArray(dataList) &&
                    dataList?.map((itm) => {
                        return (
                            <>
                                <div className="text-left">
                                    <h3>{itm?.pubTitle}</h3>
                                </div>
                                <div className="row">
                                    <p
                                        key={itm?.pubTitle}
                                        className="col-md-12"
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.pubContent}`,
                                        }}
                                    ></p>
                                </div>
                            </>
                        );
                    })}
            </div>
        </div>
    );
}
    ;
